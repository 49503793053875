import React from 'react';
import { Box, Typography, Divider, List, ListItem } from '@mui/material';

import Layout from '@gaia/Layout';
import SEO from '../components/seo';

const Principles = () => (
    <Layout>
        <SEO title="Principles of Earth Hero" />
        <Box>
            <Typography variant="h1" sx={{ color: 'primary.main', mb: { xs: 2, md: 4 } }}>
                Principles of Earth Hero
            </Typography>
            <Divider />
            <Box my={4}>
                <Typography mb={2}>
                    <Box component="span" fontWeight={700}>Our Vision: </Box>
                    <Box component="span">
                        A billion activists restore a healthy earth where life can thrive.
                    </Box>
                </Typography>
                <Typography>
                    <Box component="span" fontWeight={700}>Our Mission: </Box>
                    <Box component="span">
                        Provide tools and ideas to empower all who want to reduce emissions andcare for
                        our shared planet to improve lives.
                    </Box>
                </Typography>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Typography variant="overline">Guidance for Our Management and for Partnering with Funders:</Typography>
            <Box>
                <Typography variant="h5" component="h2" color="primary.main" mt={3}>Earth Hero’s Guiding Principles</Typography>
                <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero{' '}
                        <strong>
                            empowers individuals as agents of change: in their personal lives,
                            with family and friends, in their various communities, and within
                            government and industry - via actions of almost all types that are
                            impactful
                        </strong>
                        . Based on repeated studies, individuals can have a measurable impact
                        when: 1) their personal efforts add up in terms of total actual
                        emissions; 2) they create infrastructural change that yields ripple
                        effects; 3) they create governmental regulation/legislation change; 4)
                        they join a collective action; and 5) they help shift human culture
                        towards long-term thinking, collaboration, conscious consumption,
                        environmental protection, restoration, and other dimensions. We
                        especially support actions that also make people happier and/or
                        healthier, and shift us away from individualism and materialism.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        <strong>
                            Both individual/community and large-scale collective actions
                        </strong>{' '}
                        (by government, industry, or organizations, including organized
                        protests) <strong>are needed to succeed.</strong>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        We <strong>support quick, simple, “easy” personal actions</strong>, as
                        they may help shift the culture within a household or change a local
                        community, but they also count in motivating people towards further
                        action.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Many types of climate actions exist; we are building tools to help
                        people tailor actions to their particular situations as they learn about
                        potential actions.{' '}
                        <strong>
                            We understand that people may only be able to carry out select
                            actions at times depending on their personal situation
                        </strong>
                        , such as health, employment, and more. We support all actions at any
                        level, and then encourage further actions of any level or variety but
                        that may expand a person’s impact. Adopting a new habit is a learning
                        process, so we created our rating system to help individuals select the
                        level of ambition they can embrace.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero does not subscribe to the philosophy that only one action
                        will yield the majority of climate emission reduction or cultural impact
                        at the scale needed. For instance, a rapid reduction of the human
                        population size or the promotion of nuclear power will not be enough by
                        themselves to turn the tide.{' '}
                        <strong>
                            The combination of a variety of actions is possible and needed and
                            includes the action levels possible for an individual.
                        </strong>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        We respect and understand that{' '}
                        <strong>
                            more than one political party in any particular nation can present a
                            climate change solution(s). Earth Hero is nonpartisan
                        </strong>{' '}
                        and does not engage in any lobbying activity.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        <strong>
                            Earth Hero does not encourage violent or illegal civil disobedience.
                        </strong>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Due to the rapid onset of climate change, we not only talk and promote
                        action towards mitigating carbon emissions, but{' '}
                        <strong>also discuss community adaptation</strong> to the climate
                        crisis.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        There is no completely knowing the answer(s) to solving carbon
                        emissions. Research is still ongoing, and we{' '}
                        <strong>must grow and evolve</strong> within the role of managing
                        actions and expectations as we receive new data. Some things we propose
                        now may later be found to have an inappropriate side cost. Earth Hero{' '}
                        <strong>will follow the science</strong>, including the understanding of
                        behavior science to help achieve success.
                    </ListItem>
                </List>

                <Typography variant="caption" sx={{ display: 'inline-block' }} mt={3}>
                    In addition, we follow the Council of Nonprofits’ Principles and Practices for,
                    focusing on management, communication, diversity and inclusion, finances, donor
                    rights, partnerships, operations, evaluation, and more.{' '}
                    <a
                        href="https://www.nonprofitadvancement.org/tools-resources/standards-for-excellence/"
                        rel="noopener noreferrer nofollow"
                    >
                        https://www.nonprofitadvancement.org/tools-resources/standards-for-excellence/
                    </a>
                </Typography>

                <Typography variant="caption" sx={{ display: 'inline-block' }} mt={1}>
                    As a new organization, we seek to complete the related checklist within this
                    site,{' '}
                    <a
                        href="https://www.nonprofitadvancement.org/files/2018/05/02-Self-Assessment-Checklist-11032015-002.pdf"
                        rel="noopener noreferrer nofollow"
                    >
                        https://www.nonprofitadvancement.org/files/2018/05/02-Self-Assessment-Checklist-11032015-002.pdf
                    </a>
                    , which follows the parallel standards set by the Standards for Excellence
                    Institute,{' '}
                    <a
                        href="https://standardsforexcellence.org/home-2/code/"
                        rel="noopener noreferrer nofollow"
                    >
                        https://standardsforexcellence.org/home-2/code/
                    </a>
                    . We also have incorporated some of the standards for the state of Illinois,
                    where Earth Hero official is based:{' '}
                    <a
                        href="https://myforefront.org/wp-content/uploads/2019/09/Nonprofit-Best-Practices-2020.pdf"
                        rel="noopener noreferrer nofollow"
                    >
                        https://myforefront.org/wp-content/uploads/2019/09/Nonprofit-Best-Practices-2020.pdf
                    </a>
                </Typography>

                <Typography variant="caption" sx={{ display: 'inline-block' }} mt={1}>
                    <strong>
                        Some highlights and modifications of these standards specific to Earth Hero are
                        below:
                    </strong>
                </Typography>

                <Typography variant="h5" component="h2" color="primary.main" mt={3}>Transparency, Archival, and Accountability</Typography>
                <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        In all public materials and nonpublic solicitation materials, Earth Hero
                        will fairly represent its mission, vision, goals, and principles, as
                        well as governance structure.{' '}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        All goals, program activities and outcomes, annual financial reports,
                        and our plans for the use of finances will be open and transparent to
                        donors/funders, collaborators, and users on a clear, timely, and
                        proactive basis.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero has set a document retention policy that is available upon
                        request via{' '}
                        <a href="mailto:contact@earthhero.org">contact@earthhero.org</a>.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero will respond promptly to complaints and has a whistleblower
                        policy available upon request.
                    </ListItem>
                </List>

                <Typography variant="h5" component="h2" color="primary.main" mt={3}>Funding</Typography>
                <Box>
                    This section regards both our principles for collecting and using funds and how
                    we work with collaborators.
                    <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            Earth Hero will raise funds and seek other resources from donors/funders
                            who already reflect our mission in their support, or who clearly and
                            accurately seek, on paper, to evolve towards supporting our mission.
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            We recognize the dignity of programs and funders and respect their
                            rights following standards such as “A Donor Bill of Rights.” (
                            <a
                                href="https://afpglobal.org/donor-bill-rights"
                                rel="noopener noreferrer nofollow"
                            >
                                https://afpglobal.org/donor-bill-rights
                            </a>
                            ).
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            The Board will not collaborate with any entity that, to the best of our
                            knowledge, engages in behavior that conflicts with our vision, and/or
                            whose willful practices result in the rapid or egregious production of
                            carbon emissions.
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            At no point shall the principles of Earth Hero pre-determine and exclude
                            donors and organizations from being interviewed for funding or
                            collaborations; we consider it a climate step to share climate change
                            data and solutions – and that includes during interviews. However, Earth
                            Hero principles will be used for discussion during interviews.
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            Donors/funders or requested collaborators who have a publicly apparent,
                            strong philosophical conflict-of-interest (COI) with Earth Hero’s
                            principles, especially those that might engender public distrust in the
                            mission, goals, objectives, and content of Earth Hero or in the
                            impartial judgement in the business of Earth Hero, may be ruled out
                            during interviews and then by discussion by the Board.
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            We will ensure that the large majority of any funds provided are used
                            for purposes consistent with a donor’s intent as stated by the donor in
                            a solicitation or as otherwise directed before and during an application
                            process.
                        </ListItem>
                    </List>
                </Box>

                <Typography variant="h5" component="h2" color="primary.main" mt={3}>Engagement and Communication </Typography>
                <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Our relationship-building with users will be focused on providing
                        services to any and all individuals, while creating tools to help users
                        personalize their actions, and to do so, the lines of communication will
                        be established and built on trust, respect, and integrity.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        We consider our engagement with our users a partnership, and we will
                        seek feedback from them on ideas and existing features, as well as
                        through supporting those interested in testing mobile app and website
                        features.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero will be open to review and criticism and will be publicly
                        transparent regarding our decisions and actions.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero believes that personal information of users is private, such
                        as diet or housing arrangements, and that any such information uploaded
                        for the purpose of action-planning shall not be shared beyond the Earth
                        Hero secure system. Further details can be found under{' '}
                        <a href="/terms-of-service">Disclaimers and Terms of Use</a>.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero is against spam – considering it basically a form of
                        pollution (
                        <a
                            href="https://www.theguardian.com/environment/green-living-blog/2010/oct/21/carbon-footprint-email"
                            rel="noopener noreferrer nofollow"
                        >
                            electronic mail storage
                        </a>{' '}
                        uses{' '}
                        <a
                            href="https://phys.org/news/2015-11-carbon-footprint-email.html"
                            rel="noopener noreferrer nofollow"
                        >
                            a significant amount of energy
                        </a>
                        ). But we do occasionally need money, and we do have updates and events
                        to give news about. We will combine and minimize our emails to the
                        necessary, interesting news.
                    </ListItem>
                </List>

                <Typography variant="h5" component="h2" color="primary.main" mt={3}>
                    On-boarding Volunteers, Employees, Board members, Officers, Advisors, and
                    Collaborators
                </Typography>
                <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero seeks to be diverse and inclusive, as all peoples are
                        affected by climate change and can bring different needs, viewpoints,
                        and use cases to the forefront of action.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        At no point shall the principles regarding Earth Hero’s actions listed
                        within this document pre-determine and exclude prior to interviews
                        resource donors or Board, Officer, Advisor, Employee, or Volunteer
                        candidates, but they will be used for discussion during interviews.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Candidates who have a publicly apparent, strong philosophical COI with
                        Earth Hero’s principles as given above, especially those might engender
                        public distrust in the mission, goals, objectives, and content of Earth
                        Hero, or impartial judgement in the business of Earth Hero, may be ruled
                        out upon interviews and then discussion by the Board.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero respects the privacy of all community members onboarded
                        regarding any personal decisions on how they themselves implement
                        climate action, discussed or otherwise.
                    </ListItem>
                </List>

                <Typography variant="h5" component="h2" color="primary.main" mt={3}>Posting and Revision</Typography>
                <List sx={{ listStyle: "decimal", pl: { xs: 2.5, md: 4 } }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        The Board shall publicly make available on the Earth Hero website our
                        principles regarding climate action, monetary or other resource
                        acceptance, and others deemed appropriate.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        The publicly available examples of Earth Hero’s principles do not rule
                        out other potential principles that may be held by the current Board.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        Earth Hero further has a set of financial and scientific COI policies
                        that is available by email at{' '}
                        <a href="mailto:contact@earthhero.org">contact@earthhero.org</a>; these
                        policies will be utilized for all Board and other decision-making.{' '}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        The Board may revisit and rewrite the language of Earth Hero principles
                        to allow for the latest in scientific research.
                    </ListItem>
                </List>

                <Typography variant="caption" sx={{ display: 'inline-block' }} mt={3}>
                    This is a small sampling of the ethics and standards based on the Council of
                    Nonprofits Standards of Excellence code that we seek to achieve. You may contact
                    the Board of Earth Hero at any time with questions on our standards, via{' '}
                    <a href="mailto:contact@earthhero.org">contact@earthhero.org</a>.
                </Typography>
            </Box>
        </Box>
    </Layout>
);

export default Principles;
